import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#dzieci"> &gt; Poradnik(Dzieci)</a>
            <a
              href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/"
              className="sel"
            >
              {" "}
              &gt; Odporność dziecka - zadbaj i zwiększaj
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_1668728821.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Odporność dziecka - zadbaj i zwiększaj</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <b>
                      System immunologiczny to naturalna tarcza, która chroni
                      organizm przed drobnoustrojami – mikroorganizmami
                      wywołującymi różne choroby. Układ odpornościowy kształtuje
                      się w pierwszych dwunastu latach życia. Dlatego właśnie w
                      tym okresie rozwoju należy zwiększać odporność organizmu.
                      Istnieje na to kilka sprawdzonych metod.&nbsp;
                    </b>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Zadaniem układu odpornościowego jest ochrona przed wirusami,
                    bakteriami czy grzybami.
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    Bariera zapobiegająca infekcjom budowana jest przez nasz
                    organizm wiele lat. Dopiero osiągnięcie pełnej dojrzałości
                    immunologicznej zapewnia większą odporność na działanie{" "}
                    <a href="/slowniczek/#patogen" target="_blank">
                      patogenów
                    </a>
                    . Dlatego na każdym etapie rozwoju bardzo ważne jest dbanie
                    o zdrowie dziecka, dostarczanie mu odpowiednich pokarmów
                    i&nbsp;zapewnienie właściwej aktywności fizycznej. &nbsp;
                  </span>
                  &nbsp;&nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Niemowlę w pierwszych dniach życia chronią przeciwciała,
                    dostarczane przez matkę podczas ciąży. Później tę rolę
                    spełnia jej mleko, gdyż w pokarmie znajdują się, chroniące
                    przed wirusami,{" "}
                    <a href="/slowniczek/#immunoglobuliny" target="_blank">
                      immunoglobuliny
                    </a>
                    . Oczywiście niemowlaki karmione piersią też chorują, jednak
                    zdarza się to rzadziej, a przebieg choroby jest zdecydowanie
                    łagodniejszy. Dietę sześciomiesięcznych dzieci powinniśmy
                    wzbogacić o warzywa i owoce, a z czasem włączyć do niej
                    również chude mięso, jajka, jogurty i ryby, gdyż z tych
                    produktów najlepiej przyswajane są minerały i witaminy. Wraz
                    ze wzrostem masy ciała i przyrostem narządów wewnętrznych,
                    warto zadbać o odpowiednią dawkę składników odżywczych
                    (dzienne zapotrzebowanie na energię i składniki odżywcze
                    dziecka w wielu od 1 do 3 lat wynosi około 1000-1300 kcal,
                    natomiast dwunastolatka już 2300-2600 kcal). Jedną z
                    najlepszych kuracji uodparniających są witaminy zawarte w
                    spożywanych produktach:
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <ul>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s2">
                      A (wspomaga system samoobrony organizmu i można ją znaleźć
                      w marchwi, dyni, szpinaku czy pomidorach),&nbsp;
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s2">
                      C (tworzy barierę ochronną przed szkodliwymi
                      drobnoustrojami i występuje w czarnej porzeczce, cytrynie,
                      jabłkach i różnego rodzaju kapustach), &nbsp;
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s2">
                      D (dostarczamy ją do organizmu jedząc jaja, ryby, a także
                      pijąc mleko i produkty mleczne),&nbsp;
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s2">
                      DHA (kwas z grupy omega-3, występujący w rybach i algach
                      morskich),&nbsp;
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s2">
                      E i B6 (wspomagają walkę z&nbsp;wirusami, są dostępne w
                      orzechach, ziarnach zbóż oraz sałatach i szpinaku).
                    </span>
                    <span className="s2">&nbsp;&nbsp;</span>
                  </li>
                </ul>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  W ogólnym wzmocnieniu odporności organizmu niezbędny jest
                  także ruch, np. godzinne spacery każdego dnia lub zabawy na
                  świeżym powietrzu. Dziecko powinno być ubrane stosownie do
                  pogody, najlepiej na tzw. cebulkę. Warto pamiętać, by podczas
                  siedzenia w wózku miało na sobie jedną warstwę więcej, niż
                  mama, a podczas aktywności fizycznej – jedną mniej.
                  &nbsp;&nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <span className="s1">
                      Ważną rolę we wzmacnianiu organizmu odgrywa też sen,
                      niezbędny do regeneracji, rozwoju i&nbsp;wzrostu. Gdy
                      dziecko ma zablokowany nos (spowodowany obrzękiem lub
                      zalegającą w nim{" "}
                      <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                        wydzieliną
                      </a>
                      ), nie może spokojnie przespać nocy. Sen przerywany
                      problemami z oddychaniem osłabia, jeszcze w pełni
                      nieukształtowany,{" "}
                      <a
                        href="/slowniczek/#uklad_immunologiczny"
                        target="_blank"
                      >
                        układ immunologiczny
                      </a>
                      &nbsp;i powoduje rozdrażnienie w ciągu dnia. By tego
                      uniknąć, warto wieczorem wywietrzyć sypialnię, zadbać o
                      18-19 stopniową temperaturę w pokoju oraz – w przypadku
                      kataru – zastosować lek odblokowujący&nbsp; nos i
                      ułatwiający przez to oddychanie, np.&nbsp;
                    </span>
                  </span>
                  <a
                    href="/produkty/sudafed_xylospray_dla_dzieci/"
                    target="_blank"
                  >
                    Sudafed® XyloSpray
                  </a>
                  &nbsp; lub&nbsp;
                  <a href="/produkty/sudafed_xylospray_ha_dla_dzieci/">
                    Sudafed Xylospray HA
                  </a>
                  &nbsp;dla dzieci.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Poznaj sprawdzone metody na to jak zadbać o odporność u dzieci."
                    src="/web/kcfinder/uploads/images/166756995.jpg"
                    style={{
                      width: "380px",
                      height: "245px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid #fff",
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Dziecko podczas kataru jest szczególnie narażone na wiele
                  powikłań, np. zapalenie ucha środkowego. Nieleczone lub
                  zbagatelizowane infekcje górnych dróg oddechowych sprzyjają
                  powstawaniu stanów zapalnych w obrębie ucha. Dzieje się tak
                  dlatego, ponieważ śluz zalegający w nosie spływa do zatok i{" "}
                  <a href="/slowniczek/#trabka_sluchowa" target="_blank">
                    trąbki Eustachiusza
                  </a>
                  . U dzieci trąbka słuchowa jest położona bardziej poziomo, niż
                  u dorosłych i pozostaje cały czas otwarta, z tego powodu
                  przedostanie się do niej zalegającej wydzieliny następuje dużo
                  szybciej. Dlatego tak ważne jest natychmiastowe leczenie
                  polegające na&nbsp; zmniejszeniu{" "}
                  <a href="/slowniczek/#obrzek_blony_sluzowej" target="_blank">
                    obrzęku błony śluzowej
                  </a>
                  , które spowoduje odblokowanie nosa i usunięcie
                  wydzieliny.&nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">
                      <i>Grypa i przeziębienie. Porady lekarza rodzinnego, </i>
                      opracowanie zbiorowe, Literat, 2012.
                    </span>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Iwanowicz-Palus Grażyna,&nbsp;
                  </span>
                  <em
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Ciąża, poród, macierzyństwo
                  </em>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    , Wydawnictwo Rea, 2013.&nbsp;
                  </span>
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">
                      Zielińska-Pisklak Monika, Szeleszczuk Łukasz, Kuras
                      Marzena,{" "}
                      <i>
                        Rola witaminy C i cynku we wspomaganiu układu
                        odpornościowego
                      </i>
                      , [w:] „Lek w Polsce”, vol 23, nr 11-12’13. &nbsp;
                    </span>
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/dzieci/zatkany_nos_u_dziecka"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/GettyImages-135538267.jpg")',
                    }}
                  />
                  <span className="title">Zatkany nos u dziecka</span>
                </a>
                <a
                  href="/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_494479543.jpg")',
                    }}
                  />
                  <span className="title">
                    Walka z infekcjami - katar i kaszel u dziecka{" "}
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
